import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Inv No.', 'invoiceRef', true, 'desc', 'left'),
  new TableColumn('Inv Date', 'invoiceDate', true, 'desc', 'left'),
  new TableColumn('Vendor', 'vendorName', true, 'asc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export default Columns
