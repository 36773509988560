<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <sundrycreditor-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.invoiceID"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>
                  <router-link :to="{ path: entity.creditorInvoiceID }"
                    append>
                    {{ entity.invoiceRef }}
                  </router-link>
                </td>
                <td>{{ $filters.formatDateTimezone(entity.invoiceDate, $userInfo.locale) }}</td>
                <td>{{ entity.vendorName }}</td>
                <td class="has-text-centered">
                  <router-link class="button is-primary is-small is-inverted"
                    :to="{ path: entity.creditorInvoiceID }"
                    append>
                    <span class="icon is-medium">
                      <i class="mdi mdi-lead-pencil mdi-24px" />
                    </span>
                  </router-link>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import SundryCreditorListSideMenu from './SundryCreditorListSideMenu'
// import Pagination from '@/components/BulmaPagination'
import SundryCreditorService from './SundryCreditorService'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { EventHubTypes, AppHeaderButtonTypes } from '@/enums'
import StoreMixin from './storeMixin'
import SundryCreditorFormValidation from './SundryCreditorFormValidation'
import BulmaTable from '@/components/BulmaTable'
import Columns from './columns'
import ProtoBuf from 'protobufjs'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { KeyValuePairModel } from '@/classes/viewmodels'
import _transform from 'lodash/transform'
import { Emailer } from '@/classes'
import { DateTime } from 'luxon'

export default {
  name: 'SundrycreditorListView',
  components: {
    AppDetailHeader,
    [SundryCreditorListSideMenu.name]: SundryCreditorListSideMenu,
    // Pagination,
    BulmaTable
  },
  mixins: [DateTimeFiltersMixin, StoreMixin, SundryCreditorFormValidation],
  data() {
    return {
      entitySummaries: {},
      totalRows: 0,
      filter: {
        invoiceNo: '',
        vendorName: '',
        dateFrom: '',
        dateTo: '',
        sortColumn: Columns[1].name,
        sortOrder: Columns[1].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  created() {
    this.getData()
    this.$eventHub.$on(EventHubTypes.ToggleSideMenu, (data) => {
      this.toggle = false
      setTimeout(() => {
        this.toggle = true
      }, 200)
    })
  },
  mounted() {
    this.$v.filter.$touch()
  },
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    generateUrl(id) {
      return `sundrycreditor/${id}`
    },
    edit(id, event) {
      this.$router.push({
        name: 'SundryCreditorDetail',
        params: {
          id: id
        }
      })
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    filterList() {
      if (this.$v.filter.$error) {
        this.$notification.openNotificationWithType('danger', 'Sundry Creditor', 'Filter validation errors.')
        return false
      }
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    resetList() {
      this.filter.invoiceNo = ''
      this.filter.vendorName = ''
      this.filter.dateFrom = null
      this.filter.dateTo = null
      this.filter.sortColumn = Columns[1].name
      this.filter.sortOrder = Columns[1].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getData()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    fixDate(dateString) {
      const dt = new Date(dateString.split('T')[0])
      dt.setMinutes(dt.getMinutes() + new Date().getTimezoneOffset())
      dt.setMinutes(dt.getMinutes() + new Date().getTimezoneOffset())
      return dt.toISOString().split('.')[0] + 'Z'
    },
    fixDate2(dtx) {
      const dt = new Date(dtx.toISOString().split('T')[0])
      dt.setMinutes(dt.getMinutes() + new Date().getTimezoneOffset())
      return dt.toISOString().split('.')[0] + 'Z'
    },
    serialiseFilter() {
      const entityProto = require('../../assets/proto/sundrycreditor/SundryCreditorFilter.proto')
      const filterMessage = entityProto.SundryCreditorFilter.SundryCreditorFilterModel
      const filter = JSON.parse(JSON.stringify(this.filter))
      const buffer = filterMessage.encode(filter).finish()
      const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
      return b64encoded
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    async getData() {
      this.isTableLoading = true
      try {
        this.retrieveFilter()
        this.entitySummaries = await SundryCreditorService.getEntitySummaries(this.serialiseFilter())
        this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      } catch (e) {
        console.log(e)
      }
      this.isTableLoading = false
    },
    print() {
      let dateFrom = this.filter.dateFrom
      let dateTo = this.filter.dateTo

      if (!dateFrom) {
        dateFrom = '1900-01-01T14:00:00Z'
      } else {
        dateFrom = new Date(DateTime.fromISO(this.filter.dateFrom).toISODate()).toISOString()
      }

      if (!dateTo) {
        dateTo = '3000-01-01T14:00:00Z'
      } else {
        dateTo = new Date(DateTime.fromISO(this.filter.dateTo).toISODate()).toISOString()
      }
      let params = Object.assign({
        companyId: this.$userInfo.companyId,
        invoiceno: this.filter.invoiceNo,
        vendorname: this.filter.vendorName,
        startdate: dateFrom,
        enddate: dateTo,
        sortcolumn: this.filter.sortColumn.toUpperCase(),
        sortdirection: this.filter.sortOrder,
        pageindex: 1,
        pagesize: this.totalRows,
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      })
      params.pageIndex = 1
      params.pageSize = this.totalRows
      params = _transform(params, function (result, value, key) {
        result[key.toLowerCase()] = value
      })
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Sundry Creditor List Report'
      emailer.reportName = 'rptCreditorSundryCreditor'
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    addNew() {
      this.addStoreItem()
    }
  }
}
</script>
