/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.SundryCreditorFilter = (function() {

    /**
     * Namespace SundryCreditorFilter.
     * @exports SundryCreditorFilter
     * @namespace
     */
    var SundryCreditorFilter = {};

    SundryCreditorFilter.SundryCreditorFilterModel = (function() {

        /**
         * Properties of a SundryCreditorFilterModel.
         * @memberof SundryCreditorFilter
         * @interface ISundryCreditorFilterModel
         * @property {string|null} [invoiceNo] SundryCreditorFilterModel invoiceNo
         * @property {string|null} [vendorName] SundryCreditorFilterModel vendorName
         * @property {string|null} [dateFrom] SundryCreditorFilterModel dateFrom
         * @property {string|null} [dateTo] SundryCreditorFilterModel dateTo
         * @property {string|null} [sortColumn] SundryCreditorFilterModel sortColumn
         * @property {string|null} [sortOrder] SundryCreditorFilterModel sortOrder
         * @property {number|null} [pageIndex] SundryCreditorFilterModel pageIndex
         * @property {number|null} [pageSize] SundryCreditorFilterModel pageSize
         */

        /**
         * Constructs a new SundryCreditorFilterModel.
         * @memberof SundryCreditorFilter
         * @classdesc Represents a SundryCreditorFilterModel.
         * @implements ISundryCreditorFilterModel
         * @constructor
         * @param {SundryCreditorFilter.ISundryCreditorFilterModel=} [p] Properties to set
         */
        function SundryCreditorFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * SundryCreditorFilterModel invoiceNo.
         * @member {string|null|undefined} invoiceNo
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        SundryCreditorFilterModel.prototype.invoiceNo = null;

        /**
         * SundryCreditorFilterModel vendorName.
         * @member {string|null|undefined} vendorName
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        SundryCreditorFilterModel.prototype.vendorName = null;

        /**
         * SundryCreditorFilterModel dateFrom.
         * @member {string|null|undefined} dateFrom
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        SundryCreditorFilterModel.prototype.dateFrom = null;

        /**
         * SundryCreditorFilterModel dateTo.
         * @member {string|null|undefined} dateTo
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        SundryCreditorFilterModel.prototype.dateTo = null;

        /**
         * SundryCreditorFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        SundryCreditorFilterModel.prototype.sortColumn = null;

        /**
         * SundryCreditorFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        SundryCreditorFilterModel.prototype.sortOrder = null;

        /**
         * SundryCreditorFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        SundryCreditorFilterModel.prototype.pageIndex = null;

        /**
         * SundryCreditorFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        SundryCreditorFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * SundryCreditorFilterModel _invoiceNo.
         * @member {"invoiceNo"|undefined} _invoiceNo
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        Object.defineProperty(SundryCreditorFilterModel.prototype, "_invoiceNo", {
            get: $util.oneOfGetter($oneOfFields = ["invoiceNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SundryCreditorFilterModel _vendorName.
         * @member {"vendorName"|undefined} _vendorName
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        Object.defineProperty(SundryCreditorFilterModel.prototype, "_vendorName", {
            get: $util.oneOfGetter($oneOfFields = ["vendorName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SundryCreditorFilterModel _dateFrom.
         * @member {"dateFrom"|undefined} _dateFrom
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        Object.defineProperty(SundryCreditorFilterModel.prototype, "_dateFrom", {
            get: $util.oneOfGetter($oneOfFields = ["dateFrom"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SundryCreditorFilterModel _dateTo.
         * @member {"dateTo"|undefined} _dateTo
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        Object.defineProperty(SundryCreditorFilterModel.prototype, "_dateTo", {
            get: $util.oneOfGetter($oneOfFields = ["dateTo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SundryCreditorFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        Object.defineProperty(SundryCreditorFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SundryCreditorFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        Object.defineProperty(SundryCreditorFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SundryCreditorFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        Object.defineProperty(SundryCreditorFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SundryCreditorFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @instance
         */
        Object.defineProperty(SundryCreditorFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified SundryCreditorFilterModel message. Does not implicitly {@link SundryCreditorFilter.SundryCreditorFilterModel.verify|verify} messages.
         * @function encode
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @static
         * @param {SundryCreditorFilter.ISundryCreditorFilterModel} m SundryCreditorFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SundryCreditorFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.invoiceNo != null && Object.hasOwnProperty.call(m, "invoiceNo"))
                w.uint32(10).string(m.invoiceNo);
            if (m.vendorName != null && Object.hasOwnProperty.call(m, "vendorName"))
                w.uint32(18).string(m.vendorName);
            if (m.dateFrom != null && Object.hasOwnProperty.call(m, "dateFrom"))
                w.uint32(26).string(m.dateFrom);
            if (m.dateTo != null && Object.hasOwnProperty.call(m, "dateTo"))
                w.uint32(34).string(m.dateTo);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(42).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(50).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(56).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(64).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified SundryCreditorFilterModel message, length delimited. Does not implicitly {@link SundryCreditorFilter.SundryCreditorFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @static
         * @param {SundryCreditorFilter.ISundryCreditorFilterModel} message SundryCreditorFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SundryCreditorFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SundryCreditorFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {SundryCreditorFilter.SundryCreditorFilterModel} SundryCreditorFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SundryCreditorFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.SundryCreditorFilter.SundryCreditorFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.invoiceNo = r.string();
                    break;
                case 2:
                    m.vendorName = r.string();
                    break;
                case 3:
                    m.dateFrom = r.string();
                    break;
                case 4:
                    m.dateTo = r.string();
                    break;
                case 5:
                    m.sortColumn = r.string();
                    break;
                case 6:
                    m.sortOrder = r.string();
                    break;
                case 7:
                    m.pageIndex = r.int32();
                    break;
                case 8:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a SundryCreditorFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof SundryCreditorFilter.SundryCreditorFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {SundryCreditorFilter.SundryCreditorFilterModel} SundryCreditorFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SundryCreditorFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return SundryCreditorFilterModel;
    })();

    return SundryCreditorFilter;
})();

module.exports = $root;
